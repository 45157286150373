import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken<string>("app.config");

export interface IAppConfig {
  apiEndpoint: string;
  loginPath: string;
  logoutPath: string;
  refreshTokenPath: string;
  accessTokenObjectKey: string;
  refreshTokenObjectKey: string;
  adminRoleName: string;
  displayName: string;
  username: string;
  roles: string;
  exp: string;
  tusApiKey: string;
  siteKey: string;
}

export const AppConfig: IAppConfig = {
  apiEndpoint: 'api/v1',
  loginPath: 'account/login',
  logoutPath: 'account/logout',
  refreshTokenPath: 'account/RefreshToken',
  accessTokenObjectKey: 'access_token',
  refreshTokenObjectKey: 'refresh_token',
  adminRoleName: 'admin',
  displayName: 'displayName',
  username: 'username',
  roles: 'roles',
  exp: 'exp',
  tusApiKey: 'Apikey 26b3f019-5e99-409f-859a-ea54f208dd47',
  siteKey: '6LerfOcZAAAAACmA4lZ8dq-xxMOkovS4l99f-nRL'
};
